.allRevert h1:not([class]),
.allRevert h2:not([class]),
.allRevert h3:not([class]),
.allRevert h4:not([class]),
.allRevert h5:not([class]),
.allRevert h6:not([class]) {
  all: revert;
  position: relative;
  cursor: pointer;
}

.allRevert a {
  color: #3490dc;
  text-decoration: underline;
}

.allRevert a:hover {
  color: #1d68a7;
}

.allRevert table {
  border-collapse: collapse;
  width: 100%;
  margin: 1em 0;
}
.allRevert table th {
  background-color: #f8fafc;
  font-weight: bold;
  text-align: left;
  padding: 0.5em 1em;
  border: 1px solid #d2d6dc;
  overflow: auto;
  resize: both;
}
.allRevert table td {
  border: 1px solid #d2d6dc;
  padding: 0.5em 1em;
  overflow: auto;
  resize: both;
}
